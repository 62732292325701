import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import PageLayout from "../components/PageLayout"

export default function Home() {
  return (
    <PageLayout page="Who Are We?">
      <p className="mb-6">
        We are a small group of women based in the market town of{` `}
        <a
          href="https://en.wikipedia.org/wiki/Oakham"
          target="_blank"
          rel="noopener noreferrer"
        >
          Oakham
        </a>
        {` `}in Rutland, in the rural heart of England. Through local
        fundraising events we organise, we provide <b>direct</b> financial aid
        to extremely deprived areas in two developing countries, Chad and Peru.
        The funds that we raise are sent to Catholic Sisters living in nearby
        missions, who then manage the distribution of the money.
      </p>
      <div className="flex mb-6">
        <div className="flex-1 mr-2">
          <h3 className="mb-3 font-bold">Chad</h3>
          <StaticImage
            src="../images/chad/ChadBenoyMberi/a-well-constructed-in-2010-funded-by-aid-for-chad_5015458422_o.jpg"
            alt="Chad"
            className="mb-4"
          />
          <p>
            <br />
            We send funds to Sisters in a Catholic mission near{` `}
            <a
              href="https://www.google.com/search?q=Bénoye+Chad&amp;num=10"
              target="_blank"
              rel="noopener noreferrer"
            >
              {` `}Bénoye{` `}
            </a>
            and{` `}
            <a
              href="https://www.google.com/search?q=Mberi+Chad&amp;num=10"
              target="_blank"
              rel="noopener noreferrer"
            >
              M’beri
            </a>
            , southern Chad. This was the first mission we supported when the
            charity began in 1985.
          </p>
        </div>
        <div className="flex-1 ml-2">
          <h3 className="mb-3 font-bold">Peru</h3>
          <StaticImage
            src="../images/peru/PeruInstituoFeYAlegria/trujillo-9_4054082182_o.jpg"
            alt="Peru | Instituto | Fe y Algeria"
            className="mb-4"
          />
          <p className="mb-6">
            <br />
            Since 1989 we have sent funds to the poor in {` `}
            <a
              href="https://en.wikipedia.org/wiki/Lima"
              target="_blank"
              rel="noopener noreferrer"
            >
              Lima
            </a>
            {` `}and{` `}
            <a
              href="https://en.wikipedia.org/wiki/San_Juan_del_Oro_District"
              target="_blank"
              rel="noopener noreferrer"
            >
              San Juan del Oro
            </a>
            , in the remote Andes province of{` `}
            <a
              href="https://en.wikipedia.org/wiki/Sandia_Province"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sandia
            </a>
            .
          </p>
        </div>
      </div>
      <h2 className="mb-4 text-lg font-bold">How Can You Help?</h2>
      <p className="mb-6">
        Despite being a relatively small charity, we have only been able to
        continue through the generosity and support from many sources. As long
        as the poverty in Chad and Peru remains, we will be asking for your
        help. If you would like make a donation to our charity, please{" "}
        <Link to="/contact">contact us via thte form here</Link> or by emailing{" "}
        <a href="mailto:info@aidforchad.org">info@aidforchad.org</a>.
      </p>
      <h2 className="mb-4 text-lg font-bold">A Brief History</h2>
      <StaticImage
        src="../images/ladies.jpg"
        alt="Who We Are"
        className="mb-6"
      />

      <p className="mb-4">
        This comparatively small charity was started back in 1985 to help and
        support a small Catholic bush mission in Bénoye, southern Chad. At the
        time, Chad received no aid from the outside world, despite Michael
        Burke’s coverage of the African famine. Our aim was to feed the hungry
        and care for the children, the elderly and those who were needy. Through
        the goodness of others this vital work has been carried on.
      </p>
      <p className="mb-4">
        In 1989, our work spread to Peru, where the same order of Sisters had
        two missions: one in the shantytowns of Lima, and another in San Juan
        del Oro, a village in the Andes. Sadly, after a number of years the nuns
        at the mission in San Juan del Oro were obliged to leave the mission and
        it, therefore, had to close. As in southern Chad, our aim has been to
        support the hungry and the most needy in the missions we serve.
      </p>
      <p className="mb-4">
        In 1998, we were asked to extend our help to some of the women living in
        the ‘haciendas’ – the poverty-stricken slums in Lima, which are the
        poorest parts of the shantytowns. We agreed to try and duly received
        some of their beautiful handicrafts to sell in order to feed their
        families.
      </p>
      <p className="mb-4">
        We organise local fundraising events such as music/quiz nights, cakes
        sales and Peruvian handicrafts sales. The funds we raise are not spent
        on advertising, promotion or salaries – they go directly to missionary
        Sisters (some of whom are personally known to us) in each of the
        locations, who then distribute the funds to those in need.
      </p>
      <p className="mb-8">
        Please do{" "}
        <Link className="text-slate-500 hover:text-slate-700" to="/contact/">
          get in touch
        </Link>{" "}
        if you would like to help, or email us on{" "}
        <a
          className="text-slate-500 hover:text-slate-700"
          href="mailto:info@aidforchad.org"
        >
          info@aidforchad.org
        </a>
        .
      </p>
    </PageLayout>
  )
}
